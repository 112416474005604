const cactusArt = `
                /||\\
                ||||
                ||||
                |||| /|\\
           /|\\  |||| |||
           |||  |||| |||
           |||  |||| |||
           |||  |||| d||
           |||  |||||||/
           ||b._||||~~'
           \\||||||||
            \`~~~||||
                ||||
                ||||
~~~~~~~~~~~~~~~~||||~~~~~~~~~~~~~~
  \\/..__..--  . |||| \\/  .  ..
\\/         \\/ \\/    \\/
        .  \\/              \\/    .
. \\/             .   \\/     .
   __...--..__..__       .     \\/
\\/  .   .    \\/     \\/    __..--..

https://www.asciiart.eu/plants/cactus`;

console.log(cactusArt);